import React from 'react'
import './App.css'
import imageCompression from 'browser-image-compression'
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"; 

import "./styles/css/style.css";
import "./styles/css/style-media.css";
import "./styles/css/theme.css";
import "./styles/css/theme-media.css";
import "./styles/css/dashboard.css";
import "./styles/css/dashboard-media.css";
import FileUploader from './components/fileuploader';
import TermsCondition from './components/terms-and-condition';
import PrivacyPolicy from './components/privacy-policy';
import { Helmet } from "react-helmet";  

export default class App extends React.Component {
  constructor (...args) {
    super(...args)
  }

  render () {
    return (
      <>
        <Helmet>  
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <meta name="directory" content="submission" />
          <meta name="googlebot" content="index,follow" />
          <meta name="robots" content="index, follow, archive" />

          <title>Optimize your images on img&#123;ops&#125;</title>  
          <meta name="description" content="img{ops} makes smart compression techniques to reduce size of your JPEG and PNG files. The effect is nearly invisible but it makes a very large difference in file size!" />
          <meta name="keywords" content="image optimization, image compressor, png image reducing size, smart compression, decrease image size, aakshauhini" />

          <meta property="og:title" content="Optimize your images on img{ops}" />
          <meta property="og:description" content="img{ops} makes smart compression techniques to reduce size of your JPEG and PNG files. The effect is nearly invisible but it makes a very large difference in file size!" />

          <meta name="twitter:title" content="Optimize your images on img{ops}" />
          <meta name="twitter:description" content="img{ops} makes smart compression techniques to reduce size of your JPEG and PNG files. The effect is nearly invisible but it makes a very large difference in file size!" />

          <link rel="icon" href="assets/favicon.png" sizes="32x32" />
          <meta name="distribution" content="global" />
          <meta name="publisher" content="aakshauhini" />
          <meta name="theme-color" content="#0670ff" />
          <link rel="canonical" href="https://aakshauhini.com/" />

          <meta name="google-analytics" content="G-ZW9Y95KXH3" />
          <meta name="google-site-verification" content="v5JCP6BuiUtaVOgvJ_mlfNpbnzgZqQReMDm8mq3k9r8" />
          <meta name="msvalidate.01" content="33F9FD751B3DA4F794EFD698E0AA6020" />
          <meta name="yandex-verification" content="ff7bb44b25eb2e83" />
        </Helmet>  
          <Router>
            <Routes>
                <Route exact path='/' element={<FileUploader/>}></Route>
                <Route exact path="/terms-and-condition" element={<TermsCondition/>}></Route>
                <Route exact path="/privacy-policy" element={<PrivacyPolicy/>}></Route>
                <Route exact path='*' element={<FileUploader/>}></Route>
            </Routes>
        </Router>
      </>
    
    )
  }
};
