import React from "react";
function Features(props) {
const appName = "img{ops}"

  return (
    <>
        <div className="horizontal margin-reset-top">
            <div className="verticals twelve">
                <div className="card full">
                    <h3 data-title="Why img{ops} is good for image compression">Why img&#123;ops&#125; ?</h3>
                    <hr/>
                    <h5><span className="emoji-icon">&#128073;</span> Compress your JPEG/PNG images upto 90%</h5>
                    <p>{appName} makes smart compression techniques to reduce size of your JPEG and PNG files. The effect is nearly invisible but it makes a very large difference in file size!</p>
                    <hr/>
                    <h5><span className="emoji-icon">&#128073;</span> Light weight images for Faster website</h5>
                    <p>{appName} process the best compressed images, which will reduce weight of your web page and ultimately the website speed will be enhanced and it brings the experience of Faster website.</p>
                    <hr/>
                    <h5><span className="emoji-icon">&#128073;</span> Compress your images and improve your SEO ranking</h5>
                    <p>Optimized images plays critical role in SEO and page load speed. There are various methods to increase SEO but without optimized images its not that much efficient, because images consume most of data.</p>
                    <hr/>
                    <h5><span className="emoji-icon">&#128073;</span> Higher Lighthouse score and ranking in search engines</h5>
                    <p>Google chrome Lighthouse feature always share reports to optimize webpage with use of optimized images. This practice is the best practice for overall Quality of website.</p>
                    <hr/>
                    <h5><span className="emoji-icon">&#128073;</span> And of-courese it is free</h5>
                    <p>Optimizing images on {appName} is totally free and we don't charge any money to user. Just share your valuable words with us, that makes us happy and rich.</p>
                    <hr/>
                </div>
            </div>
        </div>
    </>
   );
}
export default Features;