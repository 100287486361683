import React from "react";

function Header(props) {
  return (
    <>
        <header role="header">
          <div className="container">
            <div className="horizontal">
              <div className="verticals twelve">
                <div className="logo-section">
                  <picture className="fit-image">
                    <a href="/">
                      <img 
                      src="assets/logo.svg" 
                      className="fit-image" 
                      alt="img{ops} | Aakshauhini"
                      data-title="img{ops} | Aakshauhini" 
                      />
                    </a>
                  </picture>
                </div>
                <div className="navi-section">
                  Version 1.0 <span>Beta</span>
                </div>
              </div>
            </div>
          </div>
        </header>
    </>
  );
}
export default Header;
