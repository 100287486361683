import React from "react";
function Stats(props) {
    
  return (
    <>
        <div className="horizontal">
            <div className="verticals twelve">
                <div className="card full">
                    <h3 data-title="Testimonials received on img{ops}">Insights that matters</h3>
                </div>
            </div>
        </div>
        <div className="horizontal">
            <div className="verticals four">
                <div className="footer-stats count1">
                    <span className="h1">10k+ visitors</span><br/>
                    <span className="h5">till now</span>
                </div>
            </div>
            <div className="verticals four">
                <div className="footer-stats count2">
                    <span className="h1">25k+ operations</span><br/>
                    <span className="h5">till now</span>
                </div>
            </div>
            <div className="verticals four">
                <div className="footer-stats count3">
                    <span className="h1">100+ GB saved</span><br/>
                    <span className="h5">till now</span>
                </div>
            </div>
        </div>
        <div className="horizontal">
            <div className="verticals twelve">
                * stats calculated on 26/01/2024 based on analytics data<br/>
                * vistors calculated based on bandwidth utilization and hits count <br/>
                * operations calculated based on convert button click and analytics data <br/>
                * data saved is calculated, assuming that a single conversion saved upto 80% size, if image is 1MB or more <br/>
            </div>
        </div>
    </>
  );
}
export default Stats;
