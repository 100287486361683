import React from "react";
function Footer(props) {
const appName = "img{ops}"

  return (
    <>
        <footer className="force-text-center" role="footer">
			<div className="container">
				<div className="horizontal">
					<div className="verticals twelve">
						<div className="logo-section">
							<picture className="fit-image">
								<a href="/">
									<img 
										src="assets/logo.svg" 
										className="fit-image" 
										alt="img{ops} | Aakshauhini" 
										data-title="img{ops} | Aakshauhini"
									/>
								</a>
							</picture>
						</div>
						<p>{appName} makes smart compression techniques to reduce size of your JPEG and PNG files. The effect is nearly invisible but it makes a very large difference in file size!</p>
						<p>Aakshauhini's {appName} - Image Compression Tool is free to use and works with any web browser (Chrome, Mozilla, Edge). All compressions are protected with 256-bit SSL encryption. Your file security and privacy are guaranteed.</p>
					</div>
				</div>
				<div className="horizontal">
					<div className="verticals twelve">
						<nav role="navigation">
							<small>
							<a href="/terms-and-condition">Terms &amp; Conditions </a> | <a href="/privacy-policy">Privacy policy</a> 
							</small>
						</nav>
					</div>
					<div className="verticals twelve">
						<p>
							<small>Aakshauhini Interactive Private Limited</small>
							<br/>
							<small>Copyright © 2023-2024 | All Rights Reserved</small>
						</p>
						<p>
							<small>
								Proud <img src="assets/india-flag.svg" className="fit-flag-india" alt="Made In Bharat With Love | aakshauhini" data-title="Made In Bharat With Love | aakshauhini" /> Product
							</small>
						</p>
					</div>
				</div>
			</div>
		</footer>
    </>
  );
}
export default Footer;
