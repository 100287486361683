import React from "react";
function FrequentlyAskedQuestions(props) {
const appName = "img{ops}"

  return (
    <>
        <div className="horizontal margin-reset-top">
            <div className="verticals twelve">
                <div className="card full">
                    <h3 data-title="Frequently Asked Questions on img{ops}">Frequently Asked Questions</h3>
                    <hr/>
                    <h5><span className="emoji-icon">&#128073;</span> Does {appName} - Image Compressor stores my personal data?</h5>
                    <p>No,  We don't store your images with us and all the compressions run at your browser. Original files will stay untouched. New compressed images will be saved on you local machine with prefix imgops_ and name of your image.</p>
                    <hr/>
                    <h5><span className="emoji-icon">&#128073;</span> What kind of images will be compressed?</h5>
                    <p>Currently {appName} support compressions of JPEG, PNG files only. {appName} makes smart compression techniques to reduce size of your JPEG and PNG files. The effect is nearly invisible but it makes a very large difference in file size!</p>
                    <hr/>
                    <h5><span className="emoji-icon">&#128073;</span> How much optimization can be acieved with {appName} - Image Compressor?</h5>
                    <p>Optimization your JPEG, PNG images upto 90% weight! Using our free online image compressor, you can reduce the size of images in KBs without losing the quality. The effect is nearly invisible.</p>
                    <hr/>
                    <h5><span className="emoji-icon">&#128073;</span> Does {appName} compressions changes the dimensions?</h5>
                    <p>No, {appName} - Image Compressor smart compression techniques does not change the images dimension, extension, color, and ratio. Output will be of same aspects with reduced weight of image.</p>
                    <hr/>
                    <h5><span className="emoji-icon">&#128073;</span> How to Decrease Image Size Without Losing Quality?</h5>
                    <p>{appName} - Image Compressor optimizes the images without compromising the quality. {appName} smart compression techniques works on browsers and can compress image size upto 90%.</p>
                    <hr/>
                </div>
            </div>
        </div>
    </>
   );
}
export default FrequentlyAskedQuestions;