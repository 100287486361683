import React from 'react'
import imageCompression from 'browser-image-compression'
import  Layout  from './layouts/layout';

export default class FileUploader extends React.Component {
  constructor (...args) {
    super(...args)
    this.compressImage = this.compressImage.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.state = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1024,
        progress: null,
        inputSize: null,
        outputSize: null,
        inputUrl: null,
        outputUrl: null,
        downloadName: null
    }
  }

  handleChange (target) {
    return (e) => {
      this.setState({ [target]: e.currentTarget.value })
    }
  }

  onProgress (p, useWebWorker) {
    const targetName = useWebWorker ? 'webWorker' : 'mainThread'
    this.setState(prevState => ({
      ...prevState,
      [targetName]: {
        ...prevState[targetName],
        progress: p
      }
    }))
  }

  async compressImage (event, useWebWorker) {
    const file = event.target.files[0];
    console.log(
      'ExifOrientation',
      await imageCompression.getExifOrientation(file)
    )
    const targetName = useWebWorker ? 'webWorker' : 'mainThread';

    this.setState({
      inputSize: (file.size / 1024 / 1024).toFixed(2),
      inputUrl: URL.createObjectURL(file)
    })

    console.log(this.state);
    var options = {
      maxSizeMB: this.state.maxSizeMB,
      maxWidthOrHeight: this.state.maxWidthOrHeight,
      useWebWorker,
      onProgress: p => this.onProgress(p, true)
    }
    const output = await imageCompression(file, options)
    console.log('output', output)
    this.setState(prevState => ({
      ...prevState,
        outputSize: (output.size / 1024 / 1024).toFixed(2),
        outputUrl: URL.createObjectURL(output),
        downloadName:"imgops_" + file.name
    }))
  }

  render () {
    const {  progress,
      inputSize,
      outputSize,
      inputUrl,
      outputUrl,
      downloadName, maxSizeMB, maxWidthOrHeight } = this.state
    return (
      < >
        
        <Layout  showFeatures={true} showTestimonial={true} showStats={true} showFrequentlyAskedQuestions={true}>

			<div className="horizontal">
				<div className="verticals twelve">
					<div className="card full">
						<h2 data-title="Optimize your images on img{ops}">&#129505; Optimize your images on img&#123;ops&#125;</h2>
					</div>
				</div>
				<div className="verticals twelve">
					<div className="card full">
						<h5 data-title="Optimize your JPEG/PNG images upto 90% weight">Optimize your <strong>JPEG/PNG</strong> images upto 90% weight</h5>
						<p>img&#123;ops&#125; makes smart compression techniques to reduce size of your JPEG and PNG files. The effect is nearly invisible but it makes a very large difference in file size!</p>
					</div>
				</div>
			</div>

			{/* compress format drop files here  */}
			<div className="horizontal">
				<div className="verticals twelve">
					<div className="card full">

						<div className="force-drop-here">
							<picture>
								<img 
								src="assets/file-compressor.png" 
								className="fit-image" 
								alt="Compress your image on img{ops}" 
								data-title="Compress your image on img{ops}"
								/>
							</picture>
							<p>
								<strong>Drop only JPEG or PNG files here</strong><br/>
								<small>1 image only, max 25 MB file.</small>
							</p>
							<div className="pseudo-upload-button button button-blue m-t-20">
								Upload JPEG/PNG image
									<input
									id="web-worker"
									type="file"
									accept="image/*"
									onChange={e => this.compressImage(e, true)}/>
								{/* <button className="button button-blue button-full">Upload images</button> */}
							</div>
						</div>

					</div>
				</div>
			</div>

		
			{outputUrl && (
			//  {/* compress format table  
			<div className="horizontal">	
				<div className="verticals twelve">
					<div className="card full">
						<div className="border-dashed output-images">
						<table className="fluid force-table">
							<thead>
								<tr>
									<th>Original</th>
									<th>Compressed</th>
									<th>Action</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>{inputSize} MB</td>
									<td>{outputSize} MB</td>
									<td><strong>
										<a className="download-btn" href={outputUrl} download={downloadName}>Download</a>
									</strong></td>
								</tr>
							</tbody>
						</table>
						</div>
					</div>
				</div>
			</div>
			)
			}

			{/* compress files example here  */}
			<div className="horizontal margin-reset-top">
				<div className="verticals twelve">
					<div className="card full">
						<h3 data-title="img{ops} can save upto 90% weight">img&#123;ops&#125; can save upto <span className="green">90% weight</span></h3>
					</div>
				</div>
			</div>


             {/* compress files shots here */}
			<div className="horizontal">
				<div className="verticals six">
					<div className="border-dashed">
						<h4>Before compression</h4>
						<picture>
							<img 
							src="assets/beautiful-fish-before.jpg" 
							className="fit-image" 
							alt="Beautiful fish image - before compression" 
							data-title="Beautiful fish image - before compression" 
							title="Beautiful fish image - before compression" 
							/>
						</picture>
						<div className="force-table force-table-shadow">
							<table className="fluid">
								<thead>
									<tr>
										<th>Weight</th>
										<th>Quality</th>
										<th>Format</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>898 Kb</td>
										<td>100%</td>
										<td>JPEG</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
				<div className="verticals six">
					<div className="border-dashed">
						<h4>After compression</h4>
						<picture>
							<img 
							src="assets/beautiful-fish-after.jpg" 
							className="fit-image" 
							alt="Beautiful fish image - after compression" 
							data-title="Beautiful fish image - after compression" 
							title="Beautiful fish image - after compression" 
							/>
						</picture>
						<div className="force-table force-table-shadow">
							<table className="fluid">
								<thead>
									<tr>
										<th>Weight</th>
										<th>Quality</th>
										<th>Format</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>268 Kb</td>
										<td>~ 95%</td>
										<td>JPEG</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>

        </Layout> 
      </>
    )
  }
};