import React from 'react';
import  Layout  from './layouts/layout';

export default class PrivacyPolicy extends React.Component {
  constructor (...args) {
    super(...args)
  }

  render () {
    
    const appName = "img{ops}"
    return (
      <>        
        <Layout showFeatures={false}  showTestimonial={false} showStats={false} showFrequentlyAskedQuestions={false}>
        
        <h3>Privacy Policy of {appName}</h3>

        <h5>These Privacy Policy outline the rules and regulations for the data policies of {appName} website.</h5>

        <p>
        Submitted data and the generated files are not kept on our servers. Every operation is done on user end.<br/>
        Submitted data and the generated files will not be shared or accessed by our company, except if it is requested by law enforcement authorities.<br/>
        In order to improve the quality of the {appName} service, we may save and analyze the metadata of your requests.<br/>
        The {appName} service does not provide any guarantees for the data optimization upto 90% in all cases.<br/>
        Google collects data and uses cookies for ad personalization and measurement for this site. Learn how Google collects and uses data. You can turn off ad personalization at any time in your Google account settings. Also, we use cookies for statistical purposes. By using this site, you consent to our use of cookies.
        </p>

        <p><strong>You must not</strong></p>
        <p>
        - Republish material from {appName}<br/>
        - Sell, rent or sub-license material from {appName}<br/>
        - Reproduce, duplicate or copy material from {appName}<br/>
        - Redistribute content from {appName}<br/>
        </p>

        <p><strong>Hyperlinking to our Content</strong></p>
        <p>
        The following organizations may link to our Website without prior written approval:<br/>
        - Government agencies;<br/>
        - Search engines;<br/>
        - News organizations;<br/>
        - Online directory distributors may link to our Website in the same manner as they hyperlink to the Websites of other listed businesses; and<br/>
        - System wide Accredited Businesses except soliciting non-profit organizations, charity shopping malls, and charity fundraising groups which may not hyperlink to our Web site.
        </p>

        <p>These organizations may link to our home page, to publications or to other Website information so long as the link: (a) is not in any way deceptive; (b) does not falsely imply sponsorship, endorsement or approval of the linking party and its products and/or services; and (c) fits within the context of the linking party's site.</p>

        <p><strong>Content Liability</strong></p>
        <p>We shall not be hold responsible for any content that appears on your Website. You agree to protect and defend us against all claims that is rising on your Website. No link(s) should appear on any Website that may be interpreted as libelous, obscene or criminal, or which infringes, otherwise violates, or advocates the infringement or other violation of, any third party rights.</p>

        <p><strong>{appName} | Aakshauhini</strong></p>
        <p>
        contact@aakshauhini.com <br/>
        Aakshauhini Interactive Pvt Ltd
        </p>

        </Layout>
      </>
    )
  }
};