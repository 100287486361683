import React from "react";
import Header from "../header/header";
import Footer from "../footer/footer";
import Features from "../features/features";
import FrequentlyAskedQuestions from "../frequently-asked-questions/frequently-asked-questions";
import Testimonial from "../testimonials/testimonial";
import Stats from "../stats/stats";

function Layout(props) {
  return (
    <div>
        {/* Layout profile */}
        <Header></Header>
        
        <main role="main" className="basic-layout">
          <div className="container">
            <div className="horizontal">
              <div className="verticals twelve">
                <div className="layout-cover layout-form">
                  <div className="layout-inner">

                  {props.children}
                  {props.showFeatures && <Features></Features>}
                  {props.showStats && <Stats></Stats>}
                  {props.showFrequentlyAskedQuestions && <FrequentlyAskedQuestions></FrequentlyAskedQuestions>}
                  {/* {props.showTestimonial && <Testimonial></Testimonial>} */}
                  
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>

        <Footer></Footer>
    </div>
  );
}
export default Layout;
